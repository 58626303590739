/* Add some content at the bottom of the video/page */
.form {
    width: 100%;
    max-width: 550px;
    height: 100%;
    min-height: 780px;
    max-height: 780px;
    /* padding: 80px 0; */
    margin: auto;
}
.logo {
    text-align: center;
}
.background {
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    z-index: -100;
    transform: translateX(-50%) translateY(-50%);
}
.darkoverlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.spacing-top {
    margin-top: 20px;
}
input[type="date"]:required:invalid::-webkit-datetime-edit {
    color: transparent;
}
input[type="date"]:focus::-webkit-datetime-edit {
    color: black !important;
}
