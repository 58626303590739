@media only screen and (min-width: 768px) {
    .img-wrapper {
        overflow: hidden;
    }
    img.hover-zoom {
        transition: all 0.5s ease 0s;
        width: 50%;
    }
    img.hover-zoom:hover {
        transform: scale(1.2);
    }
}
